.blockRow {
    display: flex;
    align-items: flex-start;
}

.blockWrapper {
    border: 1px solid black;
    border-radius: 5px;
    padding: 7.5px;
    margin: 3px;
}

.blockTitle {
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
}

.blockBlank {
    width: 15vw;
    min-width: 70px;
    height: 150px;
}

.blockBlankSelected {
    background-color: rgb(147, 235, 147);
    height: 150px;
    font-size: small;
    text-align: center;
    padding: 4px;
}

.smallBlockBlank {
    width: 15vw;
    min-width: 70px;
    height: 40.5px;
}

.smallBlockBlankSelected {
    background-color: rgb(147, 235, 147);
    height: 35px;
    font-size: small;
    text-align: center;
    padding: 4px;
}

.rowBlank {
    width: 30vw;
    min-width: 140px;
    height: 4px;
}

.rowBlankSelected {
    background-color: rgb(147, 235, 147);
    height: 50px;
    font-size: small;
    text-align: center;
    padding: 4px;
}

.rowkWrapper:hover {
    cursor: pointer;
}

.blockWrapper:hover {
    cursor: pointer;
}

.sectionHidden {
    width: 0px;
    height: 0px;
}