.mapWrapper {
  display: flex;
  overflow: auto;


  border: dashed 2px grey;
  padding: 2px;

  /* border: 25px solid transparent; */
  /* border-image: url('../../../public/images/tree-border.png');
  border-image-slice: 33% 33% 33% 33%;
  border-image-width: 1.5em 1.5em 1.5em 1.5em;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: round round; */
  margin-top: 20px;
}

.mapInvisbible {
  opacity: 0;
  height: 0;
}

.lineInRow {
  display: flex;
}

.mapLoadingBar {
  text-align: center;
  margin: auto auto;
}
