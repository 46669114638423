.collapsible .content {
  padding: 6px;
  background-color: #1f5628d7;
}
.collapsible {
  width: 1250px;
  margin-left: auto;
  margin-right: auto;
}
.collapsible .header {
  background-color: #dddddd;
  border-style: solid;
  padding: 6px;
  cursor: pointer;
  text-align: center;
  height: 40px;
  font-size: xx-large;
}
.center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.bigMama {
  align-items: center;
  text-align: left;
  margin-left: 2.5%;
}

.inputField {
  /* width: 50%; w-1/2 */
  padding-left: 1rem; /* px-4 */
  padding-right: 1rem; /* px-4 */
  padding-top: 0.5rem; /* py-2 */
  padding-bottom: 0.5rem; /* py-2 */
  border: 1px solid; /* border */
  border-radius: 0.375rem; /* rounded-md */
  outline: none; /* focus:outline-none */
  border-color: #34d399; /* focus:border-green-600 */
}
