/* @media screen and (min-width: 960px) { */
.navbarTitle {
  text-align: center;
  color: #faf8e3;
  font-size: x-large;
  padding: 10px;
}

.navbarLogoTitle {
  height: 0;
}
/* } */

@media screen and (min-width: 960px) {
  .navbarLogo {
    margin: 20px;
    height: 10vh;
  }
}

@media screen and (max-width: 960px) {
  .navbarLogo {
    height: 0;
  }
}


.nav-item:after {
  content: '';
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #ffdd40;
}

.nav-item .active {
  color: #ffdd40;
}

.nav-icon {
  display: none;
}

.nav-menu {

  display: flex;  
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


@mixin breakpoint($point) {
  @if $point ==md {

      // 768px
      @media (min-width: 48em) {
          @content;
      }
  }
}

.header {
  width: 100%;
  // height: 1px;
  align-items: center;
  text-align: center;
  padding: 0 1.5rem;
  padding-bottom: 30px;
  @include breakpoint(md) {
      padding: 0 12rem;
      padding-bottom: 10px;
  }

  transition: 0.3s ease all;

  &__content {
      overflow: hidden;
      color: black;
      margin: 0 auto;
      max-width: 1920px;
      // height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 100;

      &__nav {
          top: 0;
          right: 100%;
          bottom: 0;
          width: 100%;
          height: 100vh;
          position: fixed;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          // background: green;
          backdrop-filter: blur(30px);
          transform: translate(0);
          transition: 0.3s ease transform;


          @include breakpoint(md) {
              transform: none;
              flex-direction: row;
              background: transparent;
              width: auto;
              height: 100%;
              position: static;
          }

          ul {
            text-align: center;
              list-style: none;
              display: flex;
              flex-direction: column;

              @include breakpoint(md) {
                  flex-direction: row;
                  align-items: center;
                  margin-bottom: 0;
                  margin-right: calc(0.5rem + #{16px});
              }

              li {
                text-align: center;
                  &:not(:last-child) {
                      margin-bottom: 10px;

                      @include breakpoint(md) {
                          margin-bottom: 0;
                          margin-right: 16px;
                      }
                  }

                  a {
                      text-decoration: none;
                      color: white;
                      padding: 0.75rem 1.25rem;
                      border-radius: 12px;
                      transition: 0.3s ease all;
                      // font-size: large;
                      text-align: center;

                      &:hover {
                          background: rgba(#003262, 0.1);
                      }

                      &:active {
                          border-radius: calc(#{12px} + 6px);
                          background: linear-gradient(rgba(#003262, 0.1), rgba(white, 0.2));
                      }
                  }
                  @media screen and (max-width: 768px) {
                    a {
                      color: black !important;
                    }
                  }
              }
          }
          &.isMenu {
              transform: translate(100%);
          }
      }

      &__toggle {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 2rem;
          transition: 0.3s ease all;
          position: relative;
          color: white;

          &:hover {
              color: wheat;
          }

          @include breakpoint(md) {
              display: none;
          }
      }
  }
}