.deOffer {
  margin-left: 6%;
}

.sacredButton {
  margin: auto;
  /* width: 20%; */
  height: 10%;
  border: 0px solid;
  background: #1f5628;
  padding: 10px 30px 10px 30px;
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: large;
  border-radius: 30px;
  color: #ffffff;
}
