.walkway {
    background-color:rgb(211, 210, 210);
    width: 32px;
    height: 10px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.bigWalkway {
    background-color:rgb(211, 210, 210);
    width: 28px;
    height: 24px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.walkwayPathVertical {
    background-color:rgb(211, 210, 210);
    /* min-width: 100px; */
    /* width: ; */
    height: 100%;
    /* margin-top: 2px; */
    /* margin-bottom: 2px; */
}

.walkwayPathGate {
    height: 5%;
    background-color: black;
    color: white;
    text-align: center;
    font-size: small;
    padding: 2px;
    font-weight: bold;
    padding-top: 10px;
}

.walkwayPathHorizontal {
    background-color:rgb(211, 210, 210);
    min-height: 24px;
    width: 100%;
    /* margin-top: 2px; */
    /* margin-bottom: 2px; */
}